import LookupDict from '../lookup/LookupDict';
import TemplateRenderer from './TemplateRenderer';
import Lang from '../lang/Lang';

/**
	* This class manages template from different sources.
	* It will look up for templates in the stored sources and renders them with a TemplateRenderer.
	* For performance the manager keeps cached versions of looked up templates.
	*/
class TemplateManager {
	
	private cache:any = {};
	public lookup:LookupDict;
	public renderer:TemplateRenderer;	

	constructor() {
		this.renderer = new TemplateRenderer();
		this.lookup = new LookupDict();
	}
	
	/**
		* Renders the template with the given key with the given data into a string. 
		*/
	public render( key:string, data:any = {} ):string {
		
		if( this.cache[ key ] == undefined ) {
			this.cache[ key ] = this.renderer.compile( this.lookup.get( key ) );
		}
		
		return this.cache[ key ]( data );
	}
	
	public get context():any {
		return this.renderer.context;
	}
}

// create singleton to export.
var singleton = new TemplateManager();

/**
 * Add import function to TemplateRenderer context
 */
singleton.renderer.context["import"] = function( key:string, data?:any ) {
	return singleton.render( key, data );
}

/**
 * Add translation into context
 */
singleton.renderer.context.trans = function( key:string, fallback?:any ) {
	return Lang.translate( key, fallback );
}


export default singleton;