// import lib
import Node from '../lib/src/core/node/Node';
import View from '../lib/src/core/view/View';
import template from '../lib/src/core/template/TemplateManager';

class TextClipper extends View {

    private clippedText:string;
    private textContent:Node;
    private toggleText:Node;
    private clipped:boolean;

    constructor( private text:string, private length:number = 100, public template:string = 'textclipper' ){
        super();
        this.clippedText = this.clippText();
    }

    public render(){
        
        this.renderTemplate( this.template, { toggletext: 'weiterlesen' } );
        this.textContent = this.node.one( '.textclipper-content' );

        // check if we have to clipp text
        if( this.text.length > this.length ){
            this.clipped= true;
            var text = Node.fromHTML( template.render( 'impro.textclipper-text', { 
                text: this.clippedText,
            }));
            this.textContent.append( text );

        } else {
            this.clipped = false;
            var text = Node.fromHTML( template.render( 'impro.textclipper-text', { 
                text: this.text,
            }));
            this.textContent.append( text );
        }


        // check and register for toggling text
        this.toggleText = this.node.one( '.textclipper-toggle-text' );

        if( this.clipped ){
            this.toggleText.click.add( this.onToggleText, this );
        } else {
            this.toggleText.addClass( 'hidden' );
        }
    }

    private clippText(){
        return this.text.substr( 0, this.length ) + '...';
    }

    private onToggleText(){
        
        var text:Node;
        if( this.clipped ){
            
            text = Node.fromHTML( template.render( 'impro.textclipper-text', { 
                text: this.text,
            }));
            this.toggleText.html = 'weniger';
        } else {
            text = Node.fromHTML( template.render( 'impro.textclipper-text', { 
                text: this.clippedText,
            }));
            this.toggleText.html = 'weiterlesen';
        }

        this.textContent.empty();
        this.textContent.append( text );
        
        this.clipped = !this.clipped;

    }

}

export default TextClipper;