// import lib
import Node from '../lib/src/core/node/Node';

export interface IAudioPlayer{
    onStop();
    onPlay();
}

class AudioManager{

    private currentPlayer:IAudioPlayer = null;

    constructor( private audioTag:HTMLAudioElement ){
        this.audioTag.addEventListener( 'pause', () => {
            this.currentPlayer.onStop();
        })
    };

    /**
     * Request to play the audio with the given source
     * and the given player-origin
     * 
     * Inform the current player-origin that audio has started
     */
    public requestPlay( source:string, player:IAudioPlayer ){
        if( player != this.currentPlayer ){
            this.audioTag.pause();

            // check if we have a player allready
            if( this.currentPlayer != null ) this.currentPlayer.onStop();

            this.audioTag.src = source;
            this.currentPlayer = player;

            this.audioTag.play();
            this.currentPlayer.onPlay();

        } else {
            
            // check if we have the same source
            if( source != this.audioTag.currentSrc ){
                this.audioTag.src = source;
            }
            this.audioTag.play();
            this.currentPlayer.onPlay();
        }
    }

    /**
     * Request to stop the audio
     * 
     * Inform the player-origin that audio has stoped
     */
    public requestStop(){
        this.audioTag.pause();
        if( this.currentPlayer ) this.currentPlayer.onStop();
    }

}

export default AudioManager;