import LookupDict from '../lookup/LookupDict';
	
/**
	* A class that handles translation lookups
	*/
class Language {
	
	public lookup:LookupDict;
	
	constructor() {
		this.lookup = new LookupDict();
	}
	
	/**
		* translates the given key.
		*/
	translate( key:string, fallback?:string ):string {
		return this.lookup.get( key, fallback ) + '';
	}
}

var singleton = new Language();

	
	/**
	 * Inject translation function to template renderer if available
	 */
	/*
	if( ln.Template !== undefined ) {
		ln.Template.renderer.context.trans = function( key:string, fallback:string ) {
			return Lang.translate( key, fallback );
		};
	}
	*/


export default singleton;
