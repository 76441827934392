import Node from '../node/Node';
import template from '../template/TemplateManager';

/**
	* A common view class that has a container to render content/template into.
	*/
class View {

	public node:Node;
	
	
	/**
		* Renders the template with the given key into the container.
		*/
	public renderTemplate( key:string, data:Object = {} ):void {
		
		var newNode = Node.fromHTML( template.render( key, data ) );
		
		// replace current node with the new one.
		if( this.node && this.node.parent() ) this.node.replace( newNode );
		this.node = newNode;
	}
}

export default View;