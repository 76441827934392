// import lib
import Node from '../lib/src/core/node/Node';
import Template from '../lib/src/core/template/TemplateManager';

// import impro
import Impro from '../Impro';
import BasePanel from './BasePanel';
import { INextPanelData } from './BasePanel';
import { ICategoryPanel } from './CategoryPanel';
import CategoryPanel from './CategoryPanel';
import TextClipper from '../general/TextClipper';

export interface ITunePanel{
    id:number;
    title:string;
    information:string;
    youtube_links:Object[];
    categories:ICategoryPanel[];
}

class TunePanel extends BasePanel{

    constructor( public tune:ITunePanel, public main:Main ){
        super( main, tune.id );
    }

    /**
     * Render this panel
     */
    public render(){
        
        this.renderTemplate( 'impro.tune_panel', this.tune );

        var clipper = new TextClipper( this.tune.information, 200, 'impro.textclipper' );
        clipper.render();

        this.node.one( '.description-text' ).append( clipper.node );

        var categoryPanels:INextPanelData[] = [];

        this.tune.categories.forEach( ( category:ICategoryPanel ) => {

            var nextPanel = new CategoryPanel( category, this.main );
            nextPanel.render();
           
            categoryPanels.push( { panel:nextPanel, label:category.title } );

        });

        this.createNextPanelButtons( categoryPanels, this.node.one( '.categoryBtns' ) );

        this.createYoutubeLinks();

    }

    /**
     * Create all the youtube-links
     * and add them to this panel
     */
    private createYoutubeLinks(){

        this.tune.youtube_links.forEach( ( youtube_link ) => {
            var youtubelink = Node.fromHTML( Template.render( 'impro.panel_youtube_link', youtube_link ) );
            this.node.one( '.-youtubelinks' ).append( youtubelink );
        })

    }

}

export default TunePanel;