// import lib
import View from '../lib/src/core/view/View';
import Node from '../lib/src/core/node/Node';
import Template from '../lib/src/core/template/TemplateManager';

// import impro
import Impro from '../Impro';

export interface INextPanelData{
    panel:BasePanel;
    label:string
}

class BasePanel extends View{

    protected nextPanelBtns:Node[] = [];
    public nextPanels:BasePanel[] = [];
    protected nextPanelBtnContainer:Node;

    constructor( public main:Impro, public id:number ){
        super();
    }

    /**
     * Creates the buttons for all the panels of the next level
     */
    protected createNextPanelButtons( nextPanelDatas:INextPanelData[], btnContainer:Node ){
    
        this.nextPanelBtnContainer = btnContainer;

        nextPanelDatas.forEach( ( nextPanelData:INextPanelData) => {
            this.nextPanels.push( nextPanelData.panel );

            var nextPanelBtn = Node.fromHTML( Template.render( 'impro.panel_navigation_btn', { label: nextPanelData.label } ) );
            nextPanelBtn.click.add( this.onNextPanelBtnClicked, this );
            this.nextPanelBtns.push( nextPanelBtn );

            this.nextPanelBtnContainer.append( nextPanelBtn );

        });
    }

    /**
     * Clickhandler for the nextPanel-buttons
     */
    protected onNextPanelBtnClicked( n:Node ){
        var index = this.nextPanelBtns.indexOf( n );
        this.main.next( this.nextPanels[ index ] );
    }

}

export default BasePanel;