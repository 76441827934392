// import lib
import View from './lib/src/core/view/View';
import Node from './lib/src/core/node/Node';
import Signal from './lib/src/core/signal/Signal';
import Template from './lib/src/core/template/TemplateManager';

// import impro
import { IWelcomePanel} from './panels/WelcomePanel';
import WelcomePanel from './panels/WelcomePanel';
import CategoryPanel from './panels/CategoryPanel';
import BasePanel from './panels/BasePanel';
import Subcategory from './panels/Subcategory';
import AudioManager from './general/AudioManager';

// import templates
import improTemplates from './templates/impro';
Template.lookup.addObject( improTemplates );

import * as Modernizr from 'modernizr';


export interface TranspositionChangedCallback{
    ( transposition:string )
}

export interface IAudioAsset{
    id:number;
}

interface IImproData{
    app_data:IWelcomePanel;
    app_news:any;
    tune_id:number;
    cat_id:number;
    subcat_id:number
}

class Impro {

    private panels:BasePanel[] = [];
    private inPanel:Node;
    private outPanel:Node;
    private _homePanel:BasePanel;
    private backButton:Node;
    private homeButton:Node;
    /* private impressumButton:Node; */
    private _transposition:string = 'c';

    private tune_id:number;
    private cat_id:number;
    private subcat_id:number;

    public audioManager:AudioManager;
    public transpositionChanged:Signal<TranspositionChangedCallback>;

    private welcomePanel:WelcomePanel;

    private animEndEventNames = {
        'WebkitAnimation' : 'webkitAnimationEnd',
        'OAnimation' : 'oAnimationEnd',
        'msAnimation' : 'MSAnimationEnd',
        'animation' : 'animationend'
    };
    private animEndEventName:string;
    private support:boolean;

    constructor( public data:IImproData ){

        this.tune_id = data.tune_id;
        this.cat_id = data.cat_id;
        this.subcat_id = data.subcat_id;


        this.injectDeepLinks();


        this.welcomePanel = new WelcomePanel( this.data.app_data, this.data.app_news, this );
       
        this.backButton = Node.one( 'header .header-icon.-back' );
        this.homeButton = Node.one( 'header .header-icon.-home' );
        
        this.transpositionChanged = new Signal<TranspositionChangedCallback>();
        
        this.audioManager = new AudioManager( <HTMLAudioElement> Node.one( 'audio' ).native );
        
        // animation end event name
		this.animEndEventName = this.animEndEventNames[ Modernizr.prefixed( 'animation' ) ],
		// support css animations
		this.support = Modernizr.cssanimations;
        
    }
    
    /*
    * Sets the transposition
    */
    set transposition( transposition:string ){
        if( transposition != 'c' && transposition != 'bb' && transposition != 'eb' ) return;
        this._transposition = transposition;
        
        // make shure audio is off
        this.audioManager.requestStop();
        
        this.transpositionChanged.dispatch( this.transposition );
    }
    
    /*
    * Returns the transposition
    */
    get transposition():string{
        return this._transposition;
    }
    
    /**
     * Sets the home panel off the application
     */
    set homePanel( homePanel:BasePanel ){
        this._homePanel = homePanel;
    }
    
    /**
     * Inject deeplinks of exercises
     * 
     */
    
    private injectDeepLinks(){
        
        // travers data object
        this.data.app_data.improvisation.tunes.forEach( ( tune ) => {
            tune.categories.forEach( (category) => {
                category.subcategories.forEach( ( subcategory ) => {
                    subcategory.exercises.forEach( ( exercise ) => {
                        exercise.deepLink = tune.id + '/' + category.id + '/' + subcategory.id + '#ex' + exercise.id;
                    });
                });
            });
        })
    }
    
    /*
    * Renders the application
    */
    public render(){
        
        this.welcomePanel.render();
        
        // add the welcome panel to the stack of panels
        this.panels.push( this.welcomePanel );
        
        this.pushSubPanels();
        
        // and append the top panel to the current switch-panel
        var index = this.panels.length - 1;
        Node.one( '.-current' ).append( this.panels[index].node );
        
        this.backButton.click.add( this.previous, this );
        this.homeButton.click.add( this.goToHome, this );

        /* this.impressumButton = Node.one( '.impressum a' );
        if( this.impressumButton ) this.impressumButton.click.add( this.showImpressumPanel, this ); */
        
        // register on impressum-close
        Node.one( '.panel-impressum .close' ).click.add( this.hideImpressumPanel, this )

        this.toggleBackButton();
        this.toggleHomeButton();
        /* this.toggleImpressumButton(); */

        this.checkPanelBackground();

    }

    /**
     * Show the given messagen
     */
    public showMessage( message:string ){
        var messageNode = Node.one( '.message-container' )
        messageNode.html = message;
        messageNode.removeClass( '-off' );

        setTimeout( ( node ) => {
            node.addClass( '-off' );
        }, 3000, messageNode);

    }

    private pushSubPanels(){
        if( this.tune_id && this.cat_id && this.subcat_id ){

            // push the improvisation panel
            this.panels.push( this.welcomePanel.nextPanels[0] );

            // search the tune by id
            var tunePanel = this.welcomePanel.nextPanels[0].nextPanels.filter( ( tunePanel ) => {
                return tunePanel.id == this.tune_id;
            });

            this.panels.push( tunePanel[0] );

            // search the tune by id
            var catPanel = tunePanel[0].nextPanels.filter( ( catPanel ) => {
                return catPanel.id == this.cat_id;
            });

            this.panels.push( catPanel[0] );

            // make shure exercises of subcategory are visible
            var categoryPanel = catPanel[0] as CategoryPanel;
            categoryPanel.subcategories.forEach( ( subcatPanel:Subcategory ) => {
                if( subcatPanel.subcategory.id == this.subcat_id ){
                    subcatPanel.node.addClass( '-open' );
                }
            });
        }
    }

    /*
     * Show the next panel
     * by the given panel
     */
    public next( panel:BasePanel ){
       

        // make shure we  we are not between an animation
        if( Node.one( '.switch-panel:not(.-current)' ) ){

            this.inPanel = Node.one( '.switch-panel:not(.-current)' );
            this.outPanel = Node.one( '.switch-panel.-current' );

            // make shure panel is empty
            this.inPanel.empty();

            this.inPanel.append( panel.node );

            this.inPanel.addClass( '-current', '-moveFromRightFade', '-animating' );
            this.outPanel.addClass( '-fade' );

            // after animation
            if( this.support ){
                this.inPanel.native.addEventListener( this.animEndEventName, () => { this.reset() } );
            } else {
                this.reset();
            }

            this.panels.push( panel );

            this.toggleBackButton();
            this.toggleHomeButton();
        }

    }

    /**
     * Show the previous panel
     */
    private previous(){
        
        // make shure we are not between animation
        if( Node.one( '.switch-panel:not(.-current)' ) ){

            // make shure audio is off
            this.audioManager.requestStop();

            var current = this.panels.pop();
            var previous = this.panels[ this.panels.length - 1 ];

            this.inPanel = Node.one( '.switch-panel:not(.-current)' );
            this.outPanel = Node.one( '.switch-panel.-current' );

            this.inPanel.append( previous.node );

            this.inPanel.addClass( '-current', '-moveFromLeftFade', '-animating' );
            this.outPanel.addClass( '-fade' );

            // after animation
            if( this.support ){
                this.outPanel.native.addEventListener( 'webkitAnimationEnd', () => { this.reset(); } )
            } else {
                this.reset();
            }

            this.toggleBackButton();
            this.toggleHomeButton();

            this.checkPanelBackground();

        }

    }

    /**
     * Show the home panel of the application
     */
    private goToHome(){
        
        // make shure we are not between animation
        if( Node.one( '.switch-panel:not(.-current)' ) ){

            // make shure audio is off
            this.audioManager.requestStop();

            this.inPanel = Node.one( '.switch-panel.-notcurrent' );
            this.outPanel = Node.one( '.switch-panel.-current' );

            this.inPanel.append( this._homePanel.node );

            this.inPanel.addClass( '-current', '-moveFromLeftFade', '-animating' );
            this.outPanel.addClass( '-fade' );

            // after animation
            if( this.support ){
                this.outPanel.native.addEventListener( 'webkitAnimationEnd', () => { this.reset(); } );
            } else {
                this.reset();
            }

            while( this.panels[ this.panels.length - 1] != this._homePanel ){
                this.panels.pop();
            }

            this.toggleHomeButton();
        }
    }
    
    /**
     * Reset both panels to the unanimated state
     */
    private reset(){
        this.inPanel.native.className = 'switch-panel -current';
        this.outPanel.native.className = 'switch-panel -notcurrent';

        // make shure out panel is empty
        this.outPanel.empty();

        this.checkPanelBackground();

    }

    /**
     * Toggle the impressum panel
     */
    public showImpressumPanel(){
        Node.one( '.panel-impressum' ).addClass( '-show' );
    }

    /**
     * Toggle the impressum panel
     */
    private hideImpressumPanel(){
        Node.one( '.panel-impressum' ).removeClass( '-show' );
    }

    /**
     * Toggles the back button on the header
     */
    private toggleBackButton(){
       Node.one( '.header-table > .cell.-back .icon' ).toggleClass( '-hidden', this.panels.length == 1 );
    }

    /*
     * Toggles the home button on the header
     */
    private toggleHomeButton(){
        this.homeButton.toggleClass( '-hidden', this.panels.length < 3 );
    }

    /*
     * Toggles the home button on the header
     */
    private toggleImpressumButton(){
        Node.one( '.header-table > .cell.-impressum' ).toggleClass( '-hidden', this.panels.length != 1 );
    }

    /*
     * Set a background-image on the panel
     * if we are on the welcome panel
     */
    private checkPanelBackground(){
        if( this.panels[ this.panels.length - 1 ] == this.welcomePanel ){
            Node.one( '.-current' ).addClass( '-background-image' );
        } else {
            Node.all( '.switch-panel' ).forEach( ( node ) => {
                node.removeClass( '-background-image' );
            });
        }
    }
}

export default Impro;