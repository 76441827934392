// import lib
import Node from '../lib/src/core/node/Node';
import View from '../lib/src/core/view/View';
import Template from '../lib/src/core/template/TemplateManager';

// import impro
import Impro from '../Impro';
import CategoryPanel from './CategoryPanel';
import Exercise from './Exercise';
import { IExercise } from './Exercise';


export interface ISubcategory{
    id:number;
    title:string;
    exercises:IExercise[];
}

class Subcategory extends View{

    private toggleButton:Node;
    private arrowIcon:Node;
    private exercises:Node;
    private exerciseViews:Exercise[] = [];

    constructor( public subcategory:ISubcategory, public categoryPanel:CategoryPanel, public main:Main ){
        super();
    }

    /**
     * Render this subcategory
     */
    public render(){
        this.renderTemplate( 'impro.panel_subcategory', this.subcategory );

        this.toggleButton = this.node.one( '.subcategory-button' );
        this.toggleButton.click.add( this.toggleExercises, this );

        this.arrowIcon = this.toggleButton.one( '.icon' );

        this.exercises = this.node.one( '.exercises' );

        //render all exercises
        this.subcategory.exercises.forEach( ( exercise ) => {
            var exerciseView = new Exercise( exercise, this.main );
            exerciseView.render();
            this.exercises.append( exerciseView.node );
        });
        

    }

    /**
     * Show || hide all the exercises of this subcategory
     */
    private toggleExercises(){
        
        // on hide - stop audio
        if( this.node.hasClass( '-open' ) ){
            this.main.audioManager.requestStop()
        }
        
        this.node.toggleClass( '-open' );
        //this.toggleButton.toggleClass( '-open' );
        //this.arrowIcon.toggleClass( '-down' );
        //this.arrowIcon.toggleClass( '-up' );
    }

}

export default Subcategory;