// import lib
import Node from '../lib/src/core/node/Node';
import View from '../lib/src/core/view/View';
import Template from '../lib/src/core/template/TemplateManager';

// import impro
import Impro from '../Impro';
import { IAudioAsset } from '../Impro';
import { IAudioPlayer } from '../general/AudioManager';

import * as Clipboard from 'clipboard';

export interface IExercise{
    id:number;
    deepLink:string;
    short_instructions:string;
    instructions:string;
    notes:Object;
    audio_example_c:IAudioAsset;
    audio_example_bb:IAudioAsset;
    audio_example_eb:IAudioAsset;
}

class Exercise extends View implements IAudioPlayer{

    private toggleButton:Node;
    private arrowIcon:Node;
    private instructions:Node;
    private playButton:Node;
    private audioTag:HTMLAudioElement;
    private audioAsset:IAudioAsset;
    private playing:boolean = false;
    private copy:Clipboard;

    constructor( public exercise:IExercise, private main:Impro ){
        super();

        this.main.transpositionChanged.add( this.updateAudioSource, this );
        this.audioAsset = this.getTransposedAudio();

    }

    /**
     * Render the exercise
     */
    public render(){
        this.renderTemplate( 'impro.panel_exercise', this.exercise );

        this.toggleButton = this.node.one( '.more' );
        
        if( this.toggleButton ) {
            this.toggleButton.click.add( this.toggleInstructions, this );
            this.arrowIcon = this.toggleButton.one( '.icon' );
        }

        this.instructions = this.node.one( '.instructions' );

        this.playButton = this.node.one( '.play-button' );
        if( this.playButton ) this.playButton.click.add( this.toggleAudio, this );

        // register on the share event
        this.copy = new Clipboard( '.link > .icon-link' );

        /*if( !Clipboard.isSupported() ){
            this.node.one( '.link .unsupported' ).addClass( '-visible' );
        }*/
        this.copy.on('success', (e) => {
            this.main.showMessage( e.text + ': copied to clipboard' );
            e.clearSelection();
        });

        this.copy.on('error', (e) => {
            this.main.showMessage(  'Error: ' + e.text + ': could not be copie to clipboard' );
        });

    }

    /**
     * Toggles the instructions for the exercise
     */
    private toggleInstructions(){
        
        this.instructions.toggleClass( 'hidden' );
        this.arrowIcon.toggleClass( '-down' );
        this.arrowIcon.toggleClass( '-up' );
    }

    /**
     * Request to start || stop the audio
     */
    private toggleAudio(){
        
        if( !this.playing ){
            this.main.audioManager.requestPlay( 
            Template.context.assetPath + '/' + this.audioAsset.id + '/original.mp3',
            this );
        } else {
            this.main.audioManager.requestStop();
        }
    }

    /**
     * If audio stopped
     */
    public onStop(){
        this.playing = false;
        this.toggleIcon();
    }

    /**
     * If audio plays
     */
    public onPlay(){
        this.playing = true;
        this.toggleIcon();
    }


    /**
     * Toggle play || pause icon
     */
    private toggleIcon(){
        if( this.playButton ){
            this.playButton.toggleClass( 'icon-play', !this.playing );
            this.playButton.toggleClass( 'icon-pause', this.playing );
        }
    }

    /**
     * Update audiosource according to the transposition
     */
    private updateAudioSource(){
        this.audioAsset = this.getTransposedAudio(); 
    }

    /**
     * Get audiosource according to the transposition
     */
    private getTransposedAudio():IAudioAsset{
        if( this.main.transposition == 'c' ){
            return this.exercise.audio_example_c;
        } else if( this.main.transposition == 'bb' ){
            return this.exercise.audio_example_bb;
        } else {
            return this.exercise.audio_example_eb;
        }
    }

    /**
     * Trigger native share intent(Android)
     */
    private toggleCopyToClipboard(){
        /*var link = this.node.one( '.link' );
        var text = link.one( 'input' ).native as HTMLInputElement;

        link.one( 'input' ).toggleClass( '-visible' );*/
        
        /*try{
            var success = document.execCommand( 'copy' );
            link.addClass( '-success' );
            
            setTimeout( function(){
               link.removeClass( '-success' ); 
            }, 500 );
            this.main.showMessage( text.value + ': copied to clipboard' );

        } catch ( e ){
            this.main.showMessage( 'Error: could not copy link to clipboard' );
        }*/
    }

}

export default Exercise;