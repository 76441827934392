// import lib
import Node from '../lib/src/core/node/Node';
import Template from '../lib/src/core/template/TemplateManager';

// import impro
import Impro from '../Impro';
import BasePanel from './BasePanel';
import { INextPanelData } from './BasePanel';
import { ITunePanel } from './TunePanel';
import TunePanel from './TunePanel';
import TextClipper from '../general/TextClipper';

export interface IImprovisationPanel{
    description:string;
    tunes:ITunePanel[];
    id:number;
}

class ImprovisationPanel extends BasePanel {

    constructor( public data:IImprovisationPanel, public main:Impro ){
        super( main, data.id );

        this.main.homePanel = this;
    }

    /**
     * Render this panel
     */
    public render(){
        this.renderTemplate( 'impro.improvisation_panel', this.data );

        var clipper = new TextClipper( this.data.description, 200, 'impro.textclipper' );
        clipper.render();

        this.node.one( '.description-text' ).append( clipper.node );

        var tuneLevels:INextPanelData[] = [];

        this.data.tunes.forEach( ( tune:ITunePanel ) => {
            
            var nextPanel = new TunePanel( tune, this.main );
            nextPanel.render();

            tuneLevels.push( { panel: nextPanel, label: tune.title } );
            
        });

        this.createNextPanelButtons( tuneLevels, this.node.one( '.tuneBtns') );

    }

}

export default ImprovisationPanel;