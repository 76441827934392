import Impro from './Impro';
import TemplateContext from './general/TemplateContext';

export function setup( paths:any ){

    TemplateContext( paths );

}

export function main( data:any ){

    var impro = new Impro( data );
    impro.render();

}