import Template from '../lib/src/core/template/TemplateManager';

var context = Template.context;

context.getImage = function( file:string){
	return context.imgPath + '/' + file;
};

context.getAsset = function( image:any, preset:string = "notes", ext:string = "png" ) {
	
	var id = ( image ) ? image.id : 0;
	return context.assetPath + '/' + id + '/' + preset + '.' + ext;
};

context.getAudio = function( audio:any, preset:string = "original", ext:string = "mp3" ){
	var id = ( audio ) ? audio.id : 0;
	return context.assetPath + '/' + id + '/' + preset + '.' + ext;
};

context.getDownload = function( file:any ){
	var id = ( file ) ? file.id : 0;
	return context.downloadPath + '/' + id;
}

context.getIcon = function( icon:string ){
	return context.iconPath + '/' + icon;
}

context.truncate = function( str:string = '', length:number = 30, symbol:string = '...' ) {
	return ( str.length > length ) ? str.slice( 0, length - symbol.length) + symbol : str;
}

context.format = function( text:string = '' ){
	return text.replace( /\n/g, '<br>' );
}

context.deepLink = function( link:string ){
	return this.rootPath + link;
}

context.dateFormat = function( date:string ){
	var d = new Date( date.replace(' ', 'T') );
	return d.getDate() + '.' + ( d.getMonth() + 1 ) + '.' + d.getFullYear();
}

export default function( config ) {
	
	// copy config into context
	for( var key in config ) {
        context[key] = config[key];
    }
}