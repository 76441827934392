// import lib
import Node from '../lib/src/core/node/Node';
import View from '../lib/src/core/view/View';
import Signal from '../lib/src/core/signal/Signal';
import { EmptyCallback } from '../lib/src/core/signal/Signal';
import Template from '../lib/src/core/template/TemplateManager';

// import impro
import Impro from '../Impro';
import BasePanel from './BasePanel';
import { ISubcategory } from './Subcategory';
import Subcategory from './Subcategory';
import TextClipper from '../general/TextClipper';

export interface ICategoryPanel{
    id:number;
    title:string;
    description:string;
    subcategories:ISubcategory[];
}

class CategoryPanel extends BasePanel{

    private transpositionC:Node;
    private transpositionBb:Node;
    private transpositionEb:Node;
    public subcategories:View[] = [];

    constructor( public category:ICategoryPanel, public main:Impro ){
        super( main, category.id );

        // register on the change of transposition
        this.main.transpositionChanged.add( this.onTranspositionChanged, this );
    }

    /**
     * Render this panel
     */
    public render(){
        this.renderTemplate( 'impro.category_panel', this.category );

        var clipper = new TextClipper( this.category.description, 200, 'impro.textclipper' );
        clipper.render();

        this.node.one( '.description-text' ).append( clipper.node );

        this.transpositionC = this.node.one( '.transposition-button.-c' );
        this.transpositionBb = this.node.one( '.transposition-button.-bb' );
        this.transpositionEb = this.node.one( '.transposition-button.-eb' );

        // register on the clicks of the transposition buttons
        this.transpositionC.click.add( () => {
            this.main.transposition = 'c';
        }, this);

        this.transpositionBb.click.add( () => {
            this.main.transposition = 'bb';
        }, this);

        this.transpositionEb.click.add( () => {
            this.main.transposition = 'eb';
        }, this);

        this.category.subcategories.forEach( ( subcategory:ISubcategory ) => {
            var subcategoryView = new Subcategory( subcategory, this, this.main );
            subcategoryView.render();
            this.subcategories.push( subcategoryView );
            this.node.one( '.-subcategories' ).append( subcategoryView.node );
        });

    }

    /**
     * Handler for the transposition-changed event
     */
    private onTranspositionChanged( transposition:string ){
        this.deactivateTranspositionButtons();
        switch( transposition ){
            case 'c':
                this.transpositionC.addClass( '-active' );
                break;
            case 'bb':
                this.transpositionBb.addClass( '-active' );
                break;
            default:
                this.transpositionEb.addClass( '-active' );
                break;
        }
    }

    /**
     * Set all transposition buttons to inactive
     */
    private deactivateTranspositionButtons(){
        this.transpositionC.removeClass( '-active' );
        this.transpositionBb.removeClass( '-active' );
        this.transpositionEb.removeClass( '-active' );
    }

}

export default CategoryPanel;