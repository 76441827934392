// import lib
import Node from '../lib/src/core/node/Node';

// import impro
import Impro from '../Impro';
import BasePanel from './BasePanel';
import { INextPanelData } from './BasePanel';
import ImprovisationPanel from './ImprovisationPanel';
import { IImprovisationPanel } from './ImprovisationPanel';
import TextClipper from '../general/TextClipper';


export interface IWelcomePanel{
    welcometext:string
    improvisation:IImprovisationPanel;
    glossar:Object;
    id:number;
}

class WelcomePanel extends BasePanel{

    private impressumButton:Node;

    constructor( public data:IWelcomePanel, public news:any, public main:Impro ){
        super( main, data.id );
    }

    /**
     * Render this panel
     */
    public render(){

        this.renderTemplate( 'impro.welcome_panel', { news: this.news } );

        var clipper = new TextClipper( this.data.welcometext, 200, 'impro.textclipper' );
        clipper.render();

        this.node.one( '.welcome-text' ).append( clipper.node );

        var improvisationPanels:INextPanelData[] = [];
        var improvisationPanel = new ImprovisationPanel( this.data.improvisation, this.main );
        improvisationPanel.render();

        improvisationPanels.push( { panel: improvisationPanel, label: 'Los geht\'s' } );

        this.createNextPanelButtons( improvisationPanels, this.node.one( '.improvisationBtns') );

        // adapt styling of all navigation buttons
        this.nextPanelBtns.forEach( ( nextPanelButton:Node ) => {
            nextPanelButton.addClass( '-improvisation' );
        });

        this.impressumButton = this.node.one( '.impressum a' );
        if( this.impressumButton ) this.impressumButton.click.add( this.main.showImpressumPanel, this );

        // register on the news-teaser
        /*this.node.one( '.news-teaser' ).click.add( this.showNews, this );
        this.node.one( '.news-message .close' ).click.add( this.hideNews, this );*/

    }

    /*private showNews(){
        this.node.one( '.news-message' ).addClass( '-show' );
    }

    private hideNews(){
        this.node.one( '.news-message' ).removeClass( '-show' );
    }*/

}

export default WelcomePanel;